import React, { useState } from "react";
import { useCampaign } from "./campaignSlice";
import { useStripeUI } from "../stripe/stripeUISlice";
import { Icon } from "@rsuite/icons";
import { Grid, Row, Col } from 'rsuite';
import DopePill from "../ui/DopePill";
import DopeButton from "../ui/DopeButton";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import { IoQrCodeOutline } from "react-icons/io5";
import authApi from "../auth/authApi";
import { formatDateStringToLocaleDateString, formatToLocaleDateString } from "../utils/date";

const DispatchMailer = ({ dispatch, index, openDrawer, editable }) => {
  return (
    <div className="dispatch-mailer">
      <img src={dispatch.front_image_thumbnail_url} />

      <div className="dispatch-mailer-details">
        <div>
          <div className="product-name">{dispatch.product_label}</div>
          <div><b>{dispatch.mail_template_name}</b></div>
        </div>

        {(dispatch.mail_template_mailer_type === 'hot_lead' || dispatch.mailer_type === 'hot_lead') && (
          <div className="product-hot_lead">
            {(editable !== false && (dispatch.hot_lead_url === null || dispatch.hot_lead_phone === null)) && (
              <DopeButton
                icon={<Icon as={IoQrCodeOutline} style={{fontSize: "16px", marginRight: "4px", position: "relative", top: 1}} />}
                props={{
                  buttonClass: "text-link",
                  onClick: () => openDrawer(index),
                  label: "Setup Hot Lead",
                }}
              />
            )}
            {(dispatch.hot_lead_url !== null && dispatch.hot_lead_phone !== null) && (
              <div className="dispatch-hot-lead">
                <DopeButton
                  props={{
                    buttonClass: "text-link",
                    onClick: () => openDrawer(index),
                    label: (
                      <div>
                        <p style={{maxWidth: '300px'}} title={dispatch.hot_lead_url}>{dispatch.hot_lead_url}</p>
                        <p>{dispatch.hot_lead_phone}</p>
                      </div>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentDate = ({ dispatch }) => {
  const [stripeUI, stripeUiActions] = useStripeUI();
  const invoicePresent = !!dispatch?.purchase?.stripe_invoice_id;

  const futurePurchaseDate = {
    UPFRONT: formatToLocaleDateString(new Date().setDate(new Date().getDate() + 1)),
    AS_YOU_GO: formatDateStringToLocaleDateString(dispatch.date),
  };

  const handleClickInvoice = () => {
    stripeUiActions.setDrawerInvoiceId(dispatch.purchase.stripe_invoice_id);
  };

  const purchaseSetting = authApi.getCurrentAccount().purchase_setting;
  const paymentDate = formatToLocaleDateString((dispatch?.purchase?.created_at) || futurePurchaseDate[purchaseSetting]);
  const inTheFuture = new Date(paymentDate) > new Date();
  const displayDate = inTheFuture ? (paymentDate) : (
    <DopeButton
      props={{
        buttonClass: "text-link",
        onClick: handleClickInvoice,
        label: paymentDate,
        styles: { padding: 0 },
      }}
      disabled={!invoicePresent}
    />);

  return (
    <>
      <div className="vertical-align flex-1">
        <span>{displayDate}</span>
      </div>
    </>
  );
}

const CancelDispatchButton = ({ dispatch, campaignActions }) => {
  const [confirmingCancel, setConfirmingCancel] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const handleCancelDispatch = () => {
    setConfirmingCancel(false);
    setCancelling(true);
    campaignActions.cancelDispatch(dispatch.id).finally(() => {
      setCancelling(false);
    });
  };

  return (
    <>
      <DopeButton
        onClick={() => setConfirmingCancel(true)}
        loading={cancelling}
        disabled={cancelling}
        className="margin-8-l"
      >Cancel</DopeButton>

      <DopeConfirmationModal
        open={confirmingCancel}
        title={"Are you sure you want to cancel this sending?"}
        message={"Any previously paid for mail pieces will be refunded with credits to your account."}
        onConfirm={handleCancelDispatch}
        confirmLabel={"Confirm"}
        onCancel={() => setConfirmingCancel(false)}
      />
    </>
  );
}


const CampaignDispatchList = ({ withCancel = false, editable = true, openDrawer }) => {
  const [campaign, campaignActions] = useCampaign();
  const { dispatches } = campaign;

  return (
    <Grid fluid className="dispatch-list">
      <Row className="header">
        <Col xs={9}>Mailer</Col>
        <Col xs={5}>Send Date</Col>
        <Col xs={4}>Payment Date</Col>
        <Col xs={6}>Mailing Status</Col>
      </Row>
      {
        dispatches.map((dispatch, index) => {
          const showPaymentDate = dispatch.status != 'cancelled';
          const canCancel = withCancel && dispatch.effective_status === "scheduled";

          let status = dispatch.effective_status;

          // if (status === 'sent' && dispatch.paid !== true && campaign.name !== 'Historical Sendings') {
          //   status = 'error';
          // }

          return (
            <Row key={index}>
              <Col xs={9}>
                <DispatchMailer
                  dispatch={dispatch}
                  index={index}
                  editable={editable}
                  openDrawer={() => openDrawer(index)}
                />
              </Col>
              <Col xs={5}>{formatDateStringToLocaleDateString(dispatch.date)}</Col>
              <Col xs={4}>{showPaymentDate ? <PaymentDate dispatch={dispatch} withCancel={withCancel} /> : '---'}</Col>
              <Col xs={6}>
                <DopePill text={status} />
                {canCancel && <CancelDispatchButton dispatch={dispatch} campaignActions={campaignActions}/>}
              </Col>
            </Row>
          );
        })
      }
    </Grid>
  );
};

export {
  CampaignDispatchList,
  DispatchMailer,
};

export default CampaignDispatchList;
