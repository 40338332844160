import React from 'react';
import './DopeModal.scss';

const DopeModal = ({
  open,
  title,
  subtitle,
  message,
  footer = null,
  icon,
  backdrop = false,
  className,
  children,
}) => {
    if (!open) {
      return null;
    }

    return (
      <div className={`dope-modal ${backdrop ? 'dope-modal--backdrop' : ''} ${className || ''}`}>
        <div className="dope-modal__content">
          {(title || subtitle) && (
            <header className="dope-modal__header">
              {title && <h3>{title}</h3>}
              {message && <p>{message}</p>}
            </header>
          )}
          <div className="dope-modal__body">
            {children}
          </div>

          {footer && (
            <footer className="dope-modal__footer">
              {footer}
            </footer>
          )}
        </div>
      </div>
    );
  };


export default DopeModal;
