import React, { useState } from "react";
import { MdOutlineCheckCircle, MdOutlineFileDownload } from "react-icons/md";
import { Icon } from "@rsuite/icons";
import { Loader } from 'rsuite';

import DopeApi from "../services/DopeApi";
import { useDopeUI } from "../ui/dopeUISlice";


import './Eddm.scss';
import { useEddmUI } from "./eddmUISlice";

const mailRoutePiece = new DopeApi('mail_route_piece');
const dispatchApi = new DopeApi('dispatch');

export const nextStatus = {
  scheduled: { action: 'mark_as_printed', next: 'printed' },
  printed: { action: 'mark_as_sent', next: 'sent' },
};

const EddmOrderDropdown = ({ rowData, reloadTable }) => {
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [eddmUI, eddmUIActions] = useEddmUI();

  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const handleCSVDownload = async () => { // rowData is the dispatch object
    setDownloadingCSV(true);
    try {
      await mailRoutePiece.exportCSV({ filters: [{ field: 'dispatch_id', operator: '=', value: rowData.id }] }, `EDDM_Dispatch_${rowData.id}`);
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: `Error exporting CSV`, type: 'error' });
    } finally {
      setDownloadingCSV(false);
    }
  };

  const handlePDFDownload = async () => {
    setDownloadingPDF(true);
    try {
      const frontPromise = dispatchApi.downloadMemberPDF(rowData.id, 'eddm_art_pdf', { side: 'front' }, `eddm_dispatch_id_${rowData.id}_front_art.pdf`);
      const backPromise = dispatchApi.downloadMemberPDF(rowData.id, 'eddm_art_pdf', { side: 'back' }, `eddm_dispatch_id_${rowData.id}_back_art.pdf`);
      await Promise.all([frontPromise, backPromise]);
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: `Error exporting PDFs`, type: 'error' });
    } finally {
      setDownloadingPDF(false);
    }
  };

  const isActionable = rowData.status === 'printed' || (rowData.status === 'scheduled' && rowData.paid);

  return (
    <>
      <div className={`dropdown-row vertical-align row clickable ${downloadingPDF ? 'disabled' : ''}`} onClick={handlePDFDownload}>
        {downloadingPDF ? <Loader size="xs" className="color-icon-black margin-8-r" /> : <Icon as={MdOutlineFileDownload} size={18} className="color-icon-black margin-8-r" />}
        <div className="label large bold">Download design file</div>
      </div>

      <div className={`dropdown-row vertical-align row clickable ${downloadingCSV ? 'disabled' : ''}`} onClick={handleCSVDownload}>
        {downloadingCSV ? <Loader size="xs" className="color-icon-black margin-8-r" /> : <Icon as={MdOutlineFileDownload} size={18} className="color-icon-black margin-8-r" />}
        <div className="label large bold">Download ZIP Code CSV</div>
      </div>

      {isActionable && (
        <div className="dropdown-row vertical-align row clickable" onClick={() => eddmUIActions.showChangeConfirmation(rowData)}>
          <Icon as={MdOutlineCheckCircle} size={18} className="color-icon-black margin-8-r" />
          <div className="label large bold">Mark as {nextStatus[rowData.status].next}</div>
        </div>
      )}
    </>
  );
}

export default EddmOrderDropdown;
