import React from 'react';

import DopeErrorMessages from './DopeErrorMessages';
import DopeButton from './DopeButton';
import DopeModal from './DopeModal';

const DopeConfirmationModal = ({
    open = false,
    title = null,
    subtitle = null,
    message = null,
    content = null,
    errors = null,
    loading = false,
    onConfirm = null,
    onCancel = null,
    cancelLabel = 'Cancel',
    confirmLabel = 'Confirm',
    icon = null,
    confirmButtonClass = 'filled-black',
    cancelButtonClass = '',
    canConfirm = true,

  }) => {

  return (
    <DopeModal
      open={open}
      title={title}
      subtitle={subtitle}
      icon={icon}
      message={message}
      backdrop={true}
      className={'dope-modal--confirmation'}
      footer={(
        <>
          <DopeButton
            disabled={loading}
            props={{
              onClick: onCancel,
              disabled: loading,
            }}
          >
            {cancelLabel}
          </DopeButton>
          <DopeButton
            disabled={loading || !canConfirm}
            props={{
              onClick: onConfirm,
              buttonClass: `filled-black dope_button--main`,
              disabled: loading || !canConfirm,
            }}
            loading={loading}
          >
            {confirmLabel}
          </DopeButton>
        </>
      )}
    >
      <>
        {content}
        {errors && <DopeErrorMessages errors={errors} />}
      </>
    </DopeModal>
  );
};

export default DopeConfirmationModal;
