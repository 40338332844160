import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  selectedEddm: null,
  showChangeConfirmation: false,
};

export const eddmUISlice = createSlice({
  name: 'eddmUI',
  initialState,
  reducers: {
    update: (state, { payload: update }) => {
      Object.assign(state, update);
    },
    resetToInitial: (state) => {
      Object.assign(state, initialState);
    },
    showChangeConfirmation: (state, { payload }) => {
      state.selectedEddm = payload;
      state.showChangeConfirmation = true;
    },
    hideChangeConfirmation: (state) => {
      state.showChangeConfirmation = false;
      state.selectedEddm = null;
    },
  },
});

export default eddmUISlice.reducer;

export const {
  update,
  resetToInitial,
  showChangeConfirmation,
  hideChangeConfirmation,
} = eddmUISlice.actions;

export const selectEddmUI = (state) => state.eddmUI;

export function useEddmUI() {
  const dispatch = useDispatch();
  const eddmUI = useSelector(selectEddmUI);
  const actions = {
    update: (payload) => dispatch(update(payload)),
    resetToInitial: () => dispatch(resetToInitial()),
    showChangeConfirmation: (payload) => dispatch(showChangeConfirmation(payload)),
    hideChangeConfirmation: () => dispatch(hideChangeConfirmation()),
  };

  return [eddmUI, actions];
}
