import React, { useState } from "react";


import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

import { Link } from "react-router-dom";
import authApi from "../auth/authApi";
import { MdCopyAll } from "react-icons/md";
import { useCampaignUI } from "./campaignUISlice";

const iconStyles = {
  marginRight: "16px",
  color: "#292B2E",
  fontSize: "18px",
};

const CampaignDropdown = ({ rowData: campaign, reloadTable, getUrl, getDisabled }) => {
  const isAdmin = authApi.currentUserHasAdminPrivileges();
  const [campaignUI, campaignUIActions] = useCampaignUI();

  const url = getUrl(campaign);
  const disabled = getDisabled(campaign);
  const linkDisabledClass = disabled ? "disabled" : "";

  const isDraft = campaign.status === "draft";
  const viewLabel = isDraft ? "Edit Campaign" : "View Campaign";

  const discardButton = isDraft && (
    <div onClick={() => campaignUIActions.showDeleteConfirmation(campaign)} className="vertical-align row clickable text margin-16-b label large bold"><TrashIcon style={iconStyles} /> Discard Draft</div>
  );

  const cloneButton = isAdmin && !campaign?.trigger_full_name && (
    <div onClick={() => campaignUIActions.showCloneConfirmation(campaign)} className="vertical-align row clickable text margin-16-b margin-16-t label large bold"><MdCopyAll style={iconStyles} /> Clone Campaign</div>
  );

  return (
    <>
      <Link to={url} disabled={disabled} className={`vertical-align row clickable margin-8-tb label large bold ${linkDisabledClass}`}><EditIcon style={iconStyles} /> {viewLabel}</Link>
      {cloneButton}
      {discardButton}
    </>
  );
}

export default CampaignDropdown;
