import { Link } from "react-router-dom";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";

import { useAutomationUI } from './automationUISlice';

const iconStyles = {
  marginRight: "16px",
  color: "#292B2E",
  fontSize: "18px",
};

const AutomationDropdown = ({ rowData: automation, reloadTable, getUrl }) => {
  const [automationsUI, automationsUIActions] = useAutomationUI();

  const url = getUrl(automation);

  const isDraft = automation.status === "draft";
  const viewLabel = isDraft ? "Edit Automation" : "View Automation";

  const discardButton = isDraft && (
    <div onClick={() => automationsUIActions.showDeleteConfirmation(automation)} className="vertical-align row clickable text margin-16-b label large bold"><TrashIcon style={iconStyles} /> Discard Draft</div>
  );

  return (
    <>
      <Link to={url} className={`vertical-align row clickable margin-8-tb label large bold`}><EditIcon style={iconStyles} /> {viewLabel}</Link>
      {discardButton}
    </>
  );
}

export default AutomationDropdown;
