import React, { useState, useEffect } from "react";

import DopeApi from "../services/DopeApi";
import { formatDate } from "../utils/date";

import { Modal } from "rsuite";
import { PiEyeBold } from "react-icons/pi";
import { PiEyeClosed } from "react-icons/pi";

import DopeButton from "../ui/DopeButton";
import DopeTextInput from "../ui/DopeTextInput";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";

const tokensApi = new DopeApi("account_user_token");

const AccountUserTokenItem = ({ token, onDelete, selected, onClick }) => {
  const [show, setShow] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const handleDelete = () => {
    setConfirming(false);
    tokensApi.destroy(token.id).then(onDelete);
  };

  const toggleShow = () => {
    setShow(!show);
  };

  const tokenDisplay = show ? token.token : "•".repeat(64);

  const icon = show ? <PiEyeBold /> : <PiEyeClosed />;

  const hideShowButton = (
    <DopeButton onClick={toggleShow} icon={icon} className="outlined-black sm" />
  );

  let classNames = "space-between gap-10 vertical-align pad-row margin-16-b border-radius-8";
  if (selected) {
    classNames += " border selected";
  } else {
    classNames += " background-blue-grey-shade-light";
  }

  if (onClick && !selected) {
    classNames += " clickable";
  }

  return (
    <>
      <div className={classNames} onClick={() => onClick && onClick(token)}>
        <div className="flex-1">{token.name}</div>
        <div className="flex-2 gap-xs vertical-align">{tokenDisplay} {hideShowButton}</div>
        <div className="flex-1">{formatDate(token.created_at)}</div>

        <div className="text-right">
          <DopeButton onClick={e  => {
            e.stopPropagation();
            setConfirming(true);
          }}>Revoke</DopeButton>
        </div>
      </div>

      <DopeConfirmationModal
        open={confirming}
        title={'Are you sure you want to revoke this token?'}
        subtitle={`"${token.name}"`}
        content={(
          <div>
            <p></p>
            <p>This action cannot be undone.</p>
            <p>Revoking this token will immediately disable it and any integrations using it will stop working.</p>
            <p>This action should usually only be taken if you believe the token has been compromised.</p>
          </div>
        )}
        onConfirm={handleDelete}
        onCancel={() => setConfirming(false)}
      />
    </>
  );
};

const AccountUserTokenForm = ({ show, onCancel, onCreate }) => {
  const [name, setName] = useState("");
  const [creating, setCreating] = useState(false);

  const handleSubmit = () => {
    setCreating(true);
    tokensApi.create({ name }).then(token => {
      setCreating(false);
      onCreate(token);
      handleCancel();
    });
  };

  const handleCancel = () => {
    setCreating(false);
    setName("");
    onCancel();
  };

  return (
    <Modal open={show} onClose={handleCancel}>
      <Modal.Header>
        <Modal.Title>Create Integration Token</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="label">Token Name</div>
        <DopeTextInput
          name="token_name"
          value={name}
          onChange={(update, value) => setName(value)}
        />
      </Modal.Body>
      <Modal.Footer>
        {!creating && <DopeButton onClick={handleCancel}>Cancel</DopeButton>}
        <DopeButton
          onClick={handleSubmit}
          loading={creating}
          disabled={creating || !name}
          className="filled"
        >Create</DopeButton>
      </Modal.Footer>
    </Modal>
  );
};
// TODO loading, copy
const AccountUserTokens = ({ headerClass = "header-4", headerText = "API Tokens", helpText, selectedTokenIndex, onTokenChange }) => {
  const [showForm, setShowForm] = useState(false);
  const [tokens, setTokens] = useState([]);

  const onDelete = (token) => {
    const nextTokens = tokens.filter((t) => t.id !== token.id);
    setTokens(nextTokens);
  };

  const onCreate = (token) => {
    setShowForm(false);
    setTokens([token, ...tokens]);
    onTokenChange && onTokenChange(0, token);
  };

  useEffect(() => {
    tokensApi.getMany({ page_size: 100 }).then(tokens => {
      setTokens(tokens);
      onTokenChange && onTokenChange(0, tokens[0]);
    });
  }, []);

  const createButton = (
    <DopeButton
      className="outlined-black"
      onClick={() => setShowForm(true)}
      props={{
        label: "Generate a new token",
        styles: { minWidth: "350px" }
      }}
    />
  );

  const tokenItems = tokens.map((token, i) => (
    <AccountUserTokenItem
      key={token.id}
      token={token}
      onDelete={onDelete}
      selected={i === selectedTokenIndex}
      onClick={() => onTokenChange && onTokenChange(i, token)}
    />
  ));

  return (
    <>
      <div>
        <div className={`${headerClass} margin-4-b`}>{headerText}</div>
        {helpText && (<div className="help-text">{helpText}</div>)}
        <div className="margin-tb">{createButton}</div>
        {tokenItems}
      </div>

      <AccountUserTokenForm
        show={showForm}
        onCancel={() => setShowForm(false)}
        onCreate={onCreate}
      />
    </>
  );
}

export default AccountUserTokens;
