import React, { useState } from "react";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeListPage from "../ui/DopeListPage";
import DopeDrawer from "../ui/DopeDrawer";
import DopeTabs from "../ui/DopeTabs";
import EddmDispatchDrawer from "../eddm/EddmDispatchDrawer";
import { useEddmUI } from "../eddm/eddmUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import { nextStatus } from "../eddm/EddmOrderDropdown";

const dispatchApi = new DopeApi('dispatch');

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true },
  { type: 'text', dataKey: 'campaign_name', label: 'Campaign Name', flexGrow: 2, sortable: true },
  { type: 'timestamp', dataKey: 'created_at', label: 'Created At', flexGrow: 2, sortable: true },
  { type: "text", dataKey: "date", label: "Send Date", flexGrow: 2, sortable: true },
  { type: "text", dataKey: "account_name", label: "Company Name", flexGrow: 2, sortable: true },
  { type: "text", dataKey: "account_email", label: "Company Email", flexGrow: 2, sortable: true },
  { type: "text", dataKey: "account_phone_number", label: "Company Phone", flexGrow: 2 },
  { type: "button", dataKey: "id", label: "View Details" },
  { type: "dropdown", dropDownType: "eddm_order", dataKey: "status" },
];

const EddmOrdersTable = () => {
  const [eddmUI, eddmUIActions] = useEddmUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const [selectedDispatch, setSelectedDispatch] = useState(null);
  const { error, tableProps, paginationProps, setScopes, reload }
    = useQuery2({
      api: dispatchApi,
      initialScopes: [{ name: 'eddm' }, { name: 'paid_and_scheduled' }],
      initialSortColumn: "date",
      initialSortDirection: "asc",
    });

  const handleClickTab = (name) => {
    setScopes([{ name: 'eddm' }, { name }]);
  };

  const handleClickDispatch = (dispatch) => {
    setSelectedDispatch(dispatch);
  };

  const markNextStatus = async () => {
    try {
      await dispatchApi.update({ id: eddmUI.selectedEddm.id, actions: [{ name: nextStatus[eddmUI.selectedEddm.status].action }] });
      reload();
      dopeUIActions.addFlashMessage({ header: `Order updated successfully`, type: 'success' });
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: `Error updating order`, type: 'error' });
    } finally {
      eddmUIActions.hideChangeConfirmation(false);
    }
  };

  const drawerContent = selectedDispatch ? <EddmDispatchDrawer eddmDispatch={selectedDispatch} /> : <></>;

  let confirmTitle = '';
  if (eddmUI.selectedEddm) {
    confirmTitle = `Change EDDM dispatch ${eddmUI.selectedEddm.id} status from ${eddmUI.selectedEddm.status} to ${nextStatus[eddmUI.selectedEddm.status].next}?`;
  }

  return (
    <div className="page">
      <h2 className="header-2 margin-40-b">EDDM Orders</h2>

      <DopeTabs onChange={handleClickTab}>
        <DopeTabs.Tab name="paid_and_scheduled" label="Ready To Process" />
        <DopeTabs.Tab name="unpaid_and_scheduled" label="Scheduled (Not Paid)" />
        <DopeTabs.Tab name="printed" label="Printing Complete" />
        <DopeTabs.Tab name="sent" label="Sent" />
        <DopeTabs.Tab name="cancelled" label="Cancelled" />
      </DopeTabs>

      <DopeListPage
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        columns={columns}
        onClickId={handleClickDispatch}
      />

      <DopeDrawer
        open={!!selectedDispatch}
        title="Dispatch Details"
        onClose={() => setSelectedDispatch(null)}
        bodyContent={drawerContent}
        size="md"
      />

      <DopeConfirmationModal
        open={eddmUI.showChangeConfirmation}
        onCancel={() => eddmUIActions.hideChangeConfirmation(false)}
        onConfirm={markNextStatus}
        title={confirmTitle}
        message="Click confirm to proceed."
      />
    </div>
  );
}

export default EddmOrdersTable;

