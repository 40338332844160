import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  confirmedPurchase: false,
  selectedAutomation: null,
  showDeleteConfirmation: false,
};

export const automationUISlice = createSlice({
  name: 'automationUI',
  initialState,
  reducers: {
    update: (state, { payload: update }) => {
      Object.assign(state, update);
    },
    resetToInitial: (state) => {
      Object.assign(state, initialState);
    },
    showDeleteConfirmation: (state, { payload }) => {
      state.selectedAutomation = payload;
      state.showDeleteConfirmation = true;
    },
    hideDeleteConfirmation: (state) => {
      state.showDeleteConfirmation = false;
      state.selectedAutomation = null;
    },
  },
});

export default automationUISlice.reducer;

export const {
  update,
  resetToInitial,
  showDeleteConfirmation,
  hideDeleteConfirmation,
} = automationUISlice.actions;

export const selectAutomationUI = (state) => state.automationUI;

export function useAutomationUI() {
  const dispatch = useDispatch();
  const automationUI = useSelector(selectAutomationUI);
  const actions = {
    update: (payload) => dispatch(update(payload)),
    resetToInitial: () => dispatch(resetToInitial()),
    showDeleteConfirmation: (payload) => dispatch(showDeleteConfirmation(payload)),
    hideDeleteConfirmation: () => dispatch(hideDeleteConfirmation()),
  };

  return [automationUI, actions];
}
