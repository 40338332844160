import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "rsuite";

import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import DopeApi from "../services/DopeApi";
import { useList } from "./listSlice";
import { useDopeUI } from "../ui/dopeUISlice";

const listApi = new DopeApi('list');

const DeleteListConfirmationModal = ({ open, onClose, reload }) => {
  const [deleteContacts, setDeleteContacts] = useState(false);

  const [list, listActions] = useList();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const navigate = useNavigate();

  const handleDeleteList = async () => {
    try {
      if (deleteContacts) {
        await listApi.update({ id: list.id, actions: [{ name: "delete_all_contacts" }] });
      }
      await listActions.destroy(list.id);
      dopeUIActions.addFlashMessage({ header: list.name + " deleted successfully", type: "success" });
      if (reload) {
        reload();
      } else {
        navigate("/lists_and_contacts");
      }
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: list.name + " could not be deleted", type: "error" });
    } finally {
      onClose();
    }
  }

  return (
    <DopeConfirmationModal
      open={open}
      size="sm"
      onCancel={onClose}
      onConfirm={handleDeleteList}
      confirmLabel="Yes, delete"
      confirmButtonClass="filled-black action-button"
      cancelButtonClass="outlined-black action-button"
      title="Are you sure you want to delete this list?"
      canConfirm={deleteContacts}
      content={
        <div>
          <p className="margin-8-b">By default, we will only delete the list and not the contacts on the list. If you would like to also delete the contacts on this list select the checkbox below.</p>
          <p className="margin-8-b"><small>Note: contacts that have been sent mail will not be deleted. If you don’t want someone to receive mail, add them to a suppression list to ensure they are removed from your campaigns and automations.</small></p>
          <Checkbox
            checked={deleteContacts}
            onChange={() => setDeleteContacts(!deleteContacts)}
          >
            I want the contacts on this list to also be deleted
          </Checkbox>
        </div>
      }
    />
  )
}

export default DeleteListConfirmationModal;
